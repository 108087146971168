import './Footer.css';

function Footer() {
  return(
    <footer>
      <hr />
      <p>Copyright &copy; 2024 Eliza Nierwińska</p>
    </footer>
  )
}

export default Footer;